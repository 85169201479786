var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_vm.houseInfo ? _c('div', {
          staticClass: "cyui-scroller-panel"
        }, [_c('div', {
          staticClass: "all"
        }, [_c('div', {
          staticClass: "all-title"
        }, [_c('p', [_vm._v(_vm._s(_vm.houseInfo.ALL_TOTAL))]), _c('p', [_vm._v("防疫点总房数")])]), _c('div', {
          staticClass: "all-message"
        }, [_c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.ALL_RENT))]), _c('p', [_vm._v("在住中")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.ALL_READYOUT))]), _c('p', [_vm._v("预离")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.ALL_FREE))]), _c('p', [_vm._v("空闲")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.ALL_DIRTY))]), _c('p', [_vm._v("脏房")])])])]), _c('div', {
          staticClass: "tabs"
        }, _vm._l(_vm.buildList, function (item, index) {
          return _c('div', {
            key: item.id,
            staticClass: "tab-pane",
            class: {
              "tab-pane-active": index === _vm.tabIndex
            },
            on: {
              "click": function ($event) {
                return _vm.toggleBuilding(item, index);
              }
            }
          }, [_vm._v(_vm._s(item.itemText))]);
        }), 0), _vm._l(_vm.houseInfo.houses, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "info"
          }, [_c('div', {
            staticClass: "info-layer"
          }, [_vm._v(_vm._s(item.floor))]), _c('div', {
            staticClass: "info-room"
          }, _vm._l(item.houses, function (subItem) {
            return _c('p', {
              key: subItem.houseId,
              style: {
                background: _vm.roomColor(subItem.state)
              },
              on: {
                "click": function ($event) {
                  return _vm.goDetail(subItem);
                }
              }
            }, [_vm._v(_vm._s(subItem.houseNo))]);
          }), 0)]);
        })], 2) : _vm._e()];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "legend"
        }, [_c('div', {
          staticClass: "legend-title"
        }, [_vm._v("图例")]), _c('div', {
          staticClass: "legend-message"
        }, [_c('p', [_c('i', {
          staticStyle: {
            "background": "#FF0000"
          }
        }), _vm._v("在住中")]), _c('p', [_c('i', {
          staticStyle: {
            "background": "#EEC403"
          }
        }), _vm._v("预离")]), _c('p', [_c('i', {
          staticStyle: {
            "background": "#77DD57"
          }
        }), _vm._v("空闲")]), _c('p', [_c('i', {
          staticStyle: {
            "background": "#4534D7"
          }
        }), _vm._v("脏房")])])]), _vm.houseInfo ? _c('div', {
          staticClass: "all foot"
        }, [_c('div', {
          staticClass: "all-title"
        }, [_c('p', [_vm._v(_vm._s(_vm.houseInfo.tOTAL))]), _c('p', [_vm._v("楼栋总房数")])]), _c('div', {
          staticClass: "all-message"
        }, [_c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.RENT))]), _c('p', [_vm._v("在住中")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.READYOUT))]), _c('p', [_vm._v("预离")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.FREE))]), _c('p', [_vm._v("空闲")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.houseInfo.dIRTY))]), _c('p', [_vm._v("脏房")])])])]) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    style: {
      height: '70%'
    },
    attrs: {
      "closeable": "",
      "close-icon": "close",
      "position": "bottom"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.houseDetails ? _c('div', {
    staticClass: "my-popup"
  }, [_c('van-cell', {
    attrs: {
      "title": "入住房源",
      "value": _vm.houseDetails.roomNo
    }
  }), _c('van-cell', {
    attrs: {
      "title": "租客姓名",
      "value": _vm.houseDetails.name
    }
  }), _c('van-cell', {
    attrs: {
      "title": "租客电话",
      "value": _vm.houseDetails.phone
    }
  }), _c('van-cell', {
    attrs: {
      "title": "证件号码",
      "value": _vm.houseDetails.idCardNo
    }
  }), _c('van-cell', {
    attrs: {
      "title": "入住日期",
      "value": _vm.houseDetails.startDate
    }
  }), _c('van-cell', {
    attrs: {
      "title": "入住天数",
      "value": _vm.houseDetails.day
    }
  }), _c('van-cell', {
    attrs: {
      "title": "离店日期",
      "value": _vm.houseDetails.endDate
    }
  }), _c('van-cell', {
    attrs: {
      "title": "租金",
      "value": _vm.houseDetails.price
    }
  }), _c('div', {
    staticClass: "my-button"
  }, [_c('van-button', {
    attrs: {
      "color": "#D1A36F"
    },
    on: {
      "click": _vm.houseOut
    }
  }, [_vm._v("快速退房")]), _c('van-button', {
    attrs: {
      "color": "#cccccc"
    },
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_vm._v("返回")])], 1)], 1) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };