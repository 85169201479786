import "core-js/modules/es.error.cause.js";
import axios from 'axios';
import store from '@store';
import { isDevelopment, baseAPIPath, baseAPIModule } from '@config/config';
import { errorCallback } from '@utils/errorCallback';
import { returnLogin, returnErrorPage } from '@utils/common';

// 创建axios实例
const instance = axios.create({
  baseURL: isDevelopment ? 'http://erjia-dcadmin.lootom-ai.com/ej-api' : 'http://uhome.erjiashangye.com/ej-api',
  // 本地开发环境使用代理
  headers: {
    // `headers` 是即将被发送的自定义请求头
    'Content-Type': 'application/json'
  },
  timeout: 50000 // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
});

// 添加请求拦截器
instance.interceptors.request.use(config => {
  // console.log(config)
  // 在发送请求之前做些什么
  if (store.getters.token) {
    config.headers.common['Authorization'] = `${store.getters.token}`; // Bearer
  }
  // store.dispatch('base/SetLoading', true)
  return config;
}, error => {
  // 对请求错误做些什么
  // console.log(error) // for debug
  // store.dispatch('base/SetLoading', false)
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(async response => {
  // 对响应数据做点什么
  // store.dispatch('base/SetLoading', false)
  // console.log(response)
  let returnInfo = errorCallback(response);
  if (returnInfo.isError) {
    if (returnInfo.errorCode === 200) {
      return response;
    } else if (returnInfo.errorCode === 401) {
      returnLogin({});
    } else {
      // 判断是否是退房申请页面
      if (response.config.url === '/checkout/managerCheckOut') {} else {
        console.log(111);
        returnErrorPage();
      }
    }
    throw new Error(returnInfo.errorMessage);
  }
  return response;
}, error => {
  // 对响应错误做点什么
  // store.dispatch('base/SetLoading', false)
  console.log('err' + error); // for debug
  console.log('err' + error.config); // for debug
  console.log('err' + error.request); // for debug
  console.log('err' + error.response); // for debug
  console.log('err' + JSON.stringify(error.response)); // for debug
  if (error.response) {
    let returnInfo = errorCallback(error.response);
    if (returnInfo.isError && returnInfo.errorCode === 401) {
      returnLogin({});
    } else {
      returnErrorPage();
    }
    return Promise.reject(new Error(returnInfo.errorMessage));
  } else {
    return Promise.reject(error);
  }
});
export default instance;