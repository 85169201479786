import request from '@utils/request';
import requestBlob from '@utils/requestBlob';
// import qs from 'qs'

/**
 * House-获取列表数据
 * url:'/steward/managerHouses'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   pageSize: '' //  分页大小
 *   pageNum: '' //  当前页数
 * }
 * @returns {*}
 */
export const getListAPI = (params = {}) => {
  return request.get('/steward/managerHouses', {
    params
  });
};

// 防疫楼栋列表
export const getBuildingApi = (data = {}) => requestBlob.post('/api/v1/open/mobileCabinHospital/getBuilding', data);
// 防疫楼栋信息
export const getHouseApi = (data = {}) => requestBlob.post('/api/v1/open/mobileCabinHospital/getHouses', data);
// 防疫退房
export const getHouseCheckOut = (data = {}) => request.post('/hospital/checkOut', data);
// 防疫打扫
export const getHouseClean = (data = {}) => request.post('/hospital/checkOutFinal', data);