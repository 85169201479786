import { mapGetters } from 'vuex';
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import filters from '@filters/filters';
import { getBuildingApi, getHouseApi, getHouseCheckOut, getHouseClean } from '@api/house';
import { getDaysBetween } from '@utils/utils';
import { Dialog, Notify } from 'vant';
export default {
  name: 'Epidemic',
  mixins: [common, filters, scrollList, getPageList],
  data() {
    return {
      headerTitle: '防疫点动态图',
      tabIndex: 0,
      show: false,
      buildList: [],
      houseInfo: null,
      houseDetails: null,
      buildingId: '',
      houseId: ''
    };
  },
  computed: {
    ...mapGetters('user', ['projectInfo']),
    roomColor() {
      return state => {
        if (state === '出租') {
          return '#FF0000 ';
        } else if (state === '预退') {
          return '#EEC403';
        } else if (state === '空闲') {
          return '#77DD57';
        } else {
          return '#4534D7';
        }
      };
    }
  },
  mounted() {
    this.initData();
    console.log(111, 'abc');
  },
  methods: {
    async initData() {
      this.$store.dispatch('base/SetLoading', true);
      const {
        dcProjectId
      } = this.projectInfo;
      // 楼栋
      let buildResult = await getBuildingApi({
        projectId: dcProjectId
      });
      this.buildList = buildResult.data.result || [];
      this.buildingId = buildResult.data.result.length > 0 ? buildResult.data.result[0].itemValue : '';
      await this.getHouseInfo();
      this.$store.dispatch('base/SetLoading', false);
    },
    // 楼层
    getHouseInfo() {
      this.$store.dispatch('base/SetLoading', true);
      const {
        dcProjectId
      } = this.projectInfo;
      getHouseApi({
        projectId: dcProjectId,
        building: this.buildingId
      }).then(res => {
        this.houseInfo = res.data.result || {};
        this.$store.dispatch('base/SetLoading', false);
      });
    },
    toggleBuilding(option, index) {
      if (index === this.tabIndex) return;
      this.tabIndex = index;
      const {
        itemValue
      } = option;
      this.buildingId = itemValue;
      this.getHouseInfo();
    },
    goDetail(options) {
      const {
        state,
        houseId,
        roomNo,
        name,
        phone,
        idCardNo,
        startDate,
        endDate,
        price
      } = options;
      this.houseId = houseId;
      if (state === '出租' || state === '预退') {
        this.show = true;
        this.houseDetails = {
          roomNo,
          name,
          phone,
          idCardNo: idCardNo.replace(/^(.{6})(?:\d+)(.{4})$/, "\$1****\$2"),
          startDate,
          day: getDaysBetween(startDate, endDate),
          endDate,
          price
        };
      } else if (state === '脏房') {
        Dialog.confirm({
          message: `请确定${roomNo}已打扫消毒`,
          beforeClose: async (action, done) => {
            if (action === 'confirm') {
              let result = await getHouseClean({
                id: this.houseId
              });
              if (result.data.code === '10000') {
                done();
                this.getHouseInfo();
                Notify({
                  type: 'primary',
                  message: '打扫成功'
                });
              }
            } else {
              done();
            }
          }
        });
      }
    },
    houseOut() {
      Dialog.confirm({
        message: '请确定是否立刻退房',
        beforeClose: async (action, done) => {
          if (action === 'confirm') {
            let result = await getHouseCheckOut({
              id: this.houseId
            });
            if (result.data.code === '10000') {
              done();
              this.show = false;
              this.getHouseInfo();
              Notify({
                type: 'primary',
                message: '退房成功'
              });
            }
          } else {
            done();
          }
        }
      });
    }
  }
};